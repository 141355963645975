import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from 'gatsby';
import { TableBasic, TableHoverable, TableIconsControls, TableSmall, TableWithSorting, MakeData as makeData, TableStyled, DataTableWithSorting, DataTableWithCustomSorting, DataTableWithScroll, DataTableWithoutPagination, DataTableWithColumnResize, DataTableWithSingleRowSelection, DataTableWithMultiRowSelection, DataTableWithCheckBoxSelection, DataTableWithCustomCheckBoxSelection, DataTableWithStickyFirstColumn, DataTableWithColumnFilter, Editable, DataTableWithCellEditable, DataTableWithGlobalFilter, DataTableWithDragAndDrop, DataTableColumnAPIInfo, DataTableColumnInstanceAPIInfo } from '../../examples/components/Table';
import Table from '../../../../src/Table';
export const query = graphql`
  query TableQuery {
    Table: componentMetadata(displayName: { eq: "Table" }) {
      ...ComponentApi_metadata
    }
    DataTable: componentMetadata(displayName: { eq: "DataTable" }) {
      ...ComponentApi_metadata
    }
    TablePagination: componentMetadata(displayName: { eq: "TablePagination" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  Due to the widespread use of tables across third-party widgets like calendars and date pickers,
  we’ve designed our tables to be opt-in. Use <code>&lt;Table&gt;</code>, and extend with custom
  styles or our various included modifier classes.
    </Overview>
    <CodeBlock hideCode={true} title='Basic Table' code={TableBasic} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock hideCode={true} title='Hoverable Rows' code={TableHoverable} mdxType="CodeBlock">
  Add prop <code>hover </code> to enable a hover state on table rows within
  <code>&lt;tbody&gt;</code>.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Tables with Icons and Controls' code={TableIconsControls} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock hideCode={true} title='Small Variants' code={TableSmall} mdxType="CodeBlock">
  All previous table styles are also available in a smaller variant, set prop <code>size</code> to{' '}
  <code>sm</code>
  <br />
  <br />
  When using small variants of tables with icons and controls, don’t forget to use the smaller variants
  of those icons and controls too!
    </CodeBlock>
    <CodeBlock hideCode={true} title='Basic Table with Sorting and other features' code={TableWithSorting} scope={{
      makeData,
      TableStyled
    }} noInline={true} mdxType="CodeBlock">
  This example demonstrates the use of <code> Table </code> component with custom sorting and
  pagination. It also uses <code> TablePagination </code> component which is basically a wrapper
  over <a href='/components/pagination'>Pagination</a> and designed to work with Tables.
    </CodeBlock>
    <LinkedHeading h='2' className='h1' id='datatable' mdxType="LinkedHeading">
  DataTable
    </LinkedHeading>
    <div>
  <p>
    <code> DataTable </code> is a wrapper around <code> Table </code>{" "}
    component that accepts props <code> data </code> and <code> columns </code> for rendering rows and column headers.
    The prop <code> columns </code> is an array of column objects and each column object must have  <code> Header </code> and <code> accessor </code> for rendering the table. Both <code> data </code> and <code> columns </code> should be memoized via <b> React.useMemo</b>.
  </p>
  <p>
      <code> Header </code> accepts value of type {`'String | Function | React.Component => JSX'`} and is used for rendering header text. The <code> accessor </code> takes a string value and it should be the same key used in <code> data </code> object. The accessor also supports deeply nested values, eg. {`'info.name'`}. Refer to the examples below for how values for <code> data </code> and <code> columns </code> can be created.
    </p>
  <div className="docs-callout docs-callout-warning p-3 pb-0 rounded-lg">
    <code>DataTable</code> component uses modus icons, refer to{" "}
    <a href="/getting-started/">Getting Started</a> page on how to enable them.
  </div>
    </div>
    <CodeBlock hideCode={true} title='Table with Sorting' code={DataTableWithSorting} noInline={true} scope={{
      makeData
    }} mdxType="CodeBlock">
  Use the prop
  <code> sortBy </code> on each column object to enable/disable sorting for individual columns. And by
  using <code> disableSorting </code> on the <code> DataTable</code>, sorting can be disabled for all
  the columns.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Table with Custom Sorting' code={DataTableWithCustomSorting} noInline={true} mdxType="CodeBlock">
  The <code>columns</code> object provides an option for custom sorting. As shown in the example
  below,<code> sortType</code> which has a type <code>{`String | Function(rowA: <Row>, rowB: <Row>, columnId: String, desc: Bool)`}</code>{' '}
  takes a function to compare 2 rows of data and order them correctly. The function must be memoized
  and it should return 1 if rowA is larger, and -1 if rowB is larger.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Table with Scroll' scope={{
      makeData
    }} noInline={true} code={DataTableWithScroll} mdxType="CodeBlock">
  Setting height and width on <code> DataTable </code> will enable vertical and horizontal
  scrolling.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Table with and without Client-side Pagination' scope={{
      makeData
    }} noInline={true} code={DataTableWithoutPagination} mdxType="CodeBlock">
  Pagination is displayed by default, to disable it use the prop <code> disablePagination </code>.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Table with Columns Resizing' scope={{
      makeData
    }} code={DataTableWithColumnResize} noInline={true} mdxType="CodeBlock">
  The prop <code> resizeColumns </code> on <code> DataTable </code>
  can be used to change column width. When hovering above the dividing line between two columns, there
  is a visual indicator that shows the column is adjustable. To disable resizing for a specific column
  use
  <code> disableResizing </code> inside the columns object as shown in the example.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Table with Single Row Selection' scope={{
      makeData
    }} code={DataTableWithSingleRowSelection} noInline={true} mdxType="CodeBlock">
  Row selection is enabled by default, to disable it use the prop <code> disableRowSelection </code>.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Table with Multiple Row Selection' scope={{
      makeData
    }} code={DataTableWithMultiRowSelection} noInline={true} mdxType="CodeBlock">
  Single row selection is the default, to enable multi-row selection use the prop{' '}
  <code> multipleRowSelection</code>. Clicking on a row selects it and clicking again undo the
  selection.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Table with CheckBox Row Selection' scope={{
      makeData
    }} code={DataTableWithCheckBoxSelection} noInline={true} mdxType="CodeBlock">
  The prop <code> checkBoxRowSelection </code> on the
  <code> DataTable </code> component add checkbox on each row's first cell to control row selection.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Table with Custom CheckBox Row Selection' scope={{
      makeData
    }} code={DataTableWithCustomCheckBoxSelection} noInline={true} mdxType="CodeBlock">
  Row Selection Checkbox can be configured by using <code> Header </code> and
  <code> Cell </code> functions in the column object that is passed to
  <code> DataTable</code>.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Table with Sticky First Column' scope={{
      makeData
    }} code={DataTableWithStickyFirstColumn} noInline={true} mdxType="CodeBlock">
  Use the prop <code> stickyFirstColumn </code> on <code> DataTable </code> component to make first
  column fixed.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Hiding Columns' scope={{
      makeData
    }} code={DataTableWithSorting} noInline={true} mdxType="CodeBlock">
  Right clicking on a column header gives a context menu option to collapse/show columns. When a
  column is collapsed, a visual indicator with arrows is displayed and clicking on it will show the
  column.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Filter Columns' scope={{
      makeData
    }} code={DataTableWithColumnFilter} noInline={true} mdxType="CodeBlock">
  Use the field <code> Filter </code> on columns object to configure custom filter control on each
  column and use the prop <code> FilterPanel </code> to render it above the Table.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Global Search' scope={{
      makeData
    }} code={DataTableWithGlobalFilter} noInline={true} mdxType="CodeBlock">
  Using the prop <code> FilterPanel </code> a global search control can be created.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Editable Cell' scope={{
      Editable,
      makeData
    }} code={DataTableWithCellEditable} noInline={true} mdxType="CodeBlock">
  Each cell can be customized to render custom control through <code> Cell </code> prop inside
  columns object as shown below.
    </CodeBlock>
    <CodeBlock hideCode={true} title='Column Reordering by Drag and Drop' scope={{
      makeData
    }} code={DataTableWithDragAndDrop} noInline={true} mdxType="CodeBlock">
  Use the props <code> allowDrag</code>, <code> allowDrop </code> to left click and hold the column
  header to drag and drop the column in a new order. A visual indicator is shown between the header
  cells where the column will be dropped. If the drop operation is not allowed the line indicating
  the drop location will be red.
    </CodeBlock>
    <LinkedHeading h='2' className='h1' id='table-api' mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.Table} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.DataTable} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.TablePagination} mdxType="ComponentApi" />
    <LinkedHeading h='3' id='DataTableColumn' mdxType="LinkedHeading">
  DataTableColumn
    </LinkedHeading>
    <Table bordered striped mdxType="Table">
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    {DataTableColumnAPIInfo.map((item, index) => <tr key={index}>
        <td className='text-monospace'>{item.key}</td>
        <td className='text-monospace'>{item.type}</td>
        <td>{item.description}</td>
      </tr>)}
  </tbody>
    </Table>
    <LinkedHeading h='3' id='DataTableColumnInstance' mdxType="LinkedHeading">
  DataTableColumnInstance
    </LinkedHeading>
    <Table bordered striped mdxType="Table">
  <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    {DataTableColumnInstanceAPIInfo.map((item, index) => <tr key={index}>
        <td className='text-monospace'>{item.key}</td>
        <td className='text-monospace'>{item.type}</td>
        <td>{item.description}</td>
      </tr>)}
  </tbody>
    </Table>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      